import { Globe } from 'react-feather';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '__generated__/graphql';
import { mergeUrlParams } from '../helpers';
import { supportedLocales } from '../../i18n';
import UI from '../UI';
import useLocale from '../useLocale';
import useOnClickOutside from '../useOnClickOutside';

interface LanguageSelectorProps {
  locales?: Locale[];
  onChangeLocale?: (locale: Locale) => void;
  brand?: string;
}

const LanguageSelector = ({
  locales = supportedLocales.current, onChangeLocale, brand = 'gray.150',
}: LanguageSelectorProps) => {
  const { t } = useTranslation('common');
  const { locale: activeLocale } = useLocale();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdown = useRef();

  useOnClickOutside(dropdown, () => setDropdownOpen(false));

  const getTo = (locale: Locale) => mergeUrlParams({ locale });

  const Button = onChangeLocale ? UI.Button : UI.LinkButton;

  const handleClick = (locale: Locale) => {
    setDropdownOpen(false);
    onChangeLocale?.(locale);
  };

  return (
    <UI.DropdownOptionsContainer ref={dropdown}>
      <UI.Button
        onClick={() => setDropdownOpen((open) => !open)}
        sc={{ brand, muted: dropdownOpen }}
        name={t(`locales_local.${activeLocale}`)}
      >
        <UI.Icon>
          <Globe />
        </UI.Icon>
        {' '}
        {t(`locales_local.${activeLocale}`)}
        {' '}
        <UI.Caret />
      </UI.Button>

      {dropdownOpen && (
        <UI.Dropdown ref={dropdown} sc={{ open: true, alignH: 'center', alignV: 'top' }}>
          {locales.map((locale) => (
            <Button
              {...!onChangeLocale && { to: getTo(locale) }}
              sc={{
                strong: locale === activeLocale,
                color: locale === activeLocale ? 'gray.800' : 'gray.600',
              }}
              onClick={() => handleClick(locale)}
              name={t(`locales_local.${locale}`)}
              key={locale}
            >
              {t(`locales_local.${locale}`)}
            </Button>
          ))}
        </UI.Dropdown>
      )}
    </UI.DropdownOptionsContainer>
  );
};

export default LanguageSelector;
